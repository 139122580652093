@import '../../assets/scss/model.scss';
@import '../../assets/scss/color.scss';

.technician-ledger-div {
    .add-button {
        @include normalButton();
        background-color: $green_color;
        font-size: 13px;
        display: flex;
        width: max-content;

        svg {
            font-size: 18px;
            margin: 0;
            margin-right: 5px;
        }
    }

    .buttons {
        display: flex;
        gap: 10px;
        justify-content: center;
    }

    .bg_blue {
        background-color: $edit_color;
    }

    .bg_red {
        background-color: $red_color;
    }
}