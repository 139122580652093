@import '../../assets/scss/model.scss';
@import '../../assets/scss/color.scss';

.single-service-div {
    .top-div {
        padding-bottom: 15px;

        .buttons-div {
            display: flex;
            justify-content: flex-end;

            button {
                @include normalButton();
                width: 150px;
                margin: 0 5px;
                background-color: rgb(68, 68, 68);
            }
        }

    }

    .single-border {
        display: grid;
        grid-template-columns: 4fr 5fr;
        gap: 40px;

        .left {
            width: 100%;

            .card-div {
                &:last-child {
                    background-color: rgb(224, 255, 210);
                }
            }

        }
    }

    .card-div {
        margin-bottom: 35px;
        @include boxShadow();
        background-color: rgb(210, 237, 255);
        padding: 15px;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        width: 100%;

        .head-div {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            color: rgb(59, 59, 59);

            h4 {
                font-size: 15px;
                margin-left: 5px;
            }
        }

        .content-div {
            overflow: auto;

            table {
                border-collapse: collapse;
                width: 100%;
                text-align: start;
                font-size: 13px;
                font-weight: 500;

                .underline {
                    border-top: 1px rgb(158, 158, 158) dashed;
                }

                .underline-bold {
                    border-top: rgb(151, 151, 151) double;
                }

                .bold-text {
                    font-size: 14px;
                    font-weight: 700;
                }

                th {
                    text-align: start;
                }

                td {
                    padding: 3px 0;
                }
            }

        }
    }

    .no-receipt-div {
        display: grid;
        place-content: center;

        .receipt-border {
            padding: 40px 0;
            text-align: center;

            .box {
                color: rgb(168, 0, 0);

                svg {
                    font-size: 40px;
                }

                p {
                    font-size: 12px;
                    margin-top: 10px;
                    color: gray;
                    font-weight: 500;
                }
            }
        }
    }


    @media screen and (max-width:700px) {
        .single-border {
            display: grid;
            grid-template-columns: 1fr;
            gap: 20px;
        }
    }

    @media screen and (max-width:450px) {
        .single-border {
            display: grid;
            grid-template-columns: 1fr;
            gap: 10px;
        }

        .buttons-div {
            button {
                @include normalButton();
                width: 100%;
                margin-left: 0px;
            }
        }

    }
}