@import '../../../assets/scss/model.scss';

.work-type-spares-div {
    .card-div {
        margin-bottom: 35px;
        @include boxShadow();
        background-color: rgb(255, 242, 215);
        padding: 15px;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        width: 100%;

        .head-div {
            margin-bottom: 10px;

            .left {
                display: flex;
                align-items: center;
                color: rgb(59, 59, 59);

                h4 {
                    font-size: 15px;
                    margin-left: 5px;
                }
            }

            .right {
                display: flex;
                align-items: center;

                .id {
                    font-size: 13px;
                    width: max-content;
                    font-weight: 700;
                    color: rgb(240, 89, 70);
                }

                svg {
                    font-size: 15px;
                    color: rgb(70, 70, 70);
                    margin-right: 10px;
                }
            }
        }

        .content-div {

            table {

                border-collapse: collapse;
                width: 100%;
                text-align: start;
                font-size: 13px;
                font-weight: 500;

                .underline {
                    border-top: 1px rgb(158, 158, 158) dashed;
                }

                .underline-bold {
                    border-top: rgb(151, 151, 151) double;
                }

                .bold-text {
                    font-size: 14px;
                    font-weight: 700;
                }

                th {
                    text-align: start;
                }

                td {
                    padding: 4px 0;
                    border-top: 1px rgb(214, 214, 214) dashed;
                }
            }

            .no-receipt-div {
                display: grid;
                place-content: center;

                .receipt-border {
                    padding: 40px 0;
                    text-align: center;

                    .box {
                        color: rgb(168, 0, 0);

                        svg {
                            font-size: 40px;
                        }

                        p {
                            font-size: 12px;
                            margin-top: 10px;
                            color: gray;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}