@import '../../assets/scss/color.scss';
@import '../../assets/scss/model.scss';

.credit-list-div {
    .credits-top {
        display: grid;
        grid-template-columns: 1fr 4fr;

        .right {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;

            .count-card {
                h3 {
                    font-size: 24px;
                    color: black;
                }

            }
        }

        .count-card {
            text-align: center;
            overflow: hidden;
            padding: 30px 10px;

            h3 {
                font-size: 28px;
                color: $primary_color;

                span {
                    cursor: pointer;
                    margin-left: 5px;
                }
            }

            p {
                font-size: 13px;
                font-weight: 600;
            }
        }
    }

    .add-button {
        @include normalButton();
        background-color: $green_color;
        font-size: 13px;
        display: flex;
        width: max-content;

        svg {
            font-size: 18px;
            margin: 0;
            margin-right: 5px;
        }
    }

    .table-div {

        .buttons {
            display: flex;
            gap: 10px;
            justify-content: center;
        }

        .bg_blue {
            background-color: $edit_color;
        }

        .bg_user {
            background-color: teal;
        }

        table {
            text-align: center;

            tbody {
                tr {
                    td {
                        &:nth-child(3) {
                            color: gray;

                            span {
                                font-weight: 600;
                                color: black;
                            }
                        }

                        &:nth-child(5) {
                            font-weight: 700;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }


    @media screen and (max-width:750px) {
        .credits-top {
            grid-template-columns: 1fr;

            .count-card {

                padding: 15px 10px;

                h3 {
                    font-size: 30px;
                }

            }

        }
    }

    @media screen and (max-width:600px) {
        .credits-top {
            grid-template-columns: 1fr;

            .count-card {

                padding: 10px 10px;

                h3 {
                    font-size: 25px;
                }

            }

            .right {

                .count-card {
                    h3 {
                        font-size: 18px;
                    }

                    p {
                        font-size: 11px;
                    }

                }
            }

        }
    }
}