@import '../../assets/scss/color.scss';
@import '../../assets/scss/model.scss';

.compliment-list-div {
    .compliments-top {
        .top-border {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        .count-card {
            text-align: center;
            overflow: hidden;
            padding: 30px 10px;

            h3 {
                font-size: 28px;
                color: $primary_color;
            }

            p {
                font-size: 13px;
                font-weight: 600;
            }

            &:last-child {
                h3 {
                    color: rgb(180, 123, 0);
                }
            }
        }
    }

    .add-button {
        @include normalButton();
        background-color: $green_color;
        font-size: 13px;
        display: flex;
        width: max-content;

        svg {
            font-size: 18px;
            margin: 0;
            margin-right: 5px;
        }
    }

    .table-div {
        .buttons {
            display: flex;
            gap: 10px;
            justify-content: center;
        }

        .bg_blue {
            background-color: $edit_color;
        }

        table {
            text-align: center;

            tbody {
                tr {
                    td {
                        &:nth-child(3) {
                            color: gray;

                            span {
                                font-weight: 600;
                                color: black;
                            }
                        }

                        &:nth-child(4) {
                            font-weight: 700;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width:600px) {
        .compliments-top {
            grid-template-columns: 1fr;

            .count-card {

                padding: 10px 10px;

                h3 {
                    font-size: 22px;
                }

            }
        }
    }
}