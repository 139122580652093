@import '../../../assets/scss/color.scss';
@import '../../../assets/scss/model.scss';


.work-credit-history-div {
    .history-card-div {
        display: grid;
        grid-template-columns: 45px auto;
        margin: 10px 0;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        overflow: hidden;
        background-color: rgb(236, 236, 236);
        border: 1px dashed rgb(165, 165, 165);

        .confirm-div {
            background-color: $red_color;
            display: grid;
            place-content: center;

            h3 {
                transform: rotate(-90deg);
                -webkit-transform: rotate(-90deg);
                -moz-transform: rotate(-90deg);
                -ms-transform: rotate(-90deg);
                -o-transform: rotate(-90deg);
                font-size: 12px;
                font-weight: 500;
                color: white;
                text-align: center;
                min-width: max-content;
            }
        }

        .confirmed {
            background-color: $green_color;
        }

        .content-div {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 8px 12px;
            gap: 5px 0;

            .line-section-div {
                display: flex;
                justify-content: space-between;

                p {
                    font-size: 13px;
                    font-weight: 600;
                }
            }
        }
    }
}