@import '../../assets/scss/model.scss';
@import '../../assets/scss/color.scss';

.purifier-service-list-div {
    .service-list-top {
        .left {
            p {
                font-size: 13px;
                font-weight: 600;
                margin: 3px 0;
            }
        }
    }

    .refresh {
        @include normalButton();
        background-color: $edit_color;
        font-size: 13px;
        display: flex;
        width: max-content;


        svg {
            font-size: 18px;
            margin: 0;
            margin-right: 5px;
        }
    }

    .table-div {

        table {
            text-align: center;
        }
    }

    .buttons {
        display: flex;
        gap: 10px;
        justify-content: center;

        .bg_gray {
            background-color: gray;
        }
    }
}