@import '../../assets/scss/model.scss';
@import '../../assets/scss/color.scss';


.xml-download-div {
    .input-page-div {
        .section-one {
            display: grid;
            place-content: center;
            width: 100%;
            height: 250px;

            .border {
                text-align: center;

                button {
                    @include normalButton();
                    font-size: 16px;
                    padding: 15px 30px;
                    background-color: $green_color;
                }

                p {
                    font-size: 12px;
                    color: gray;
                    margin: 10px 0;

                    &:last-child {
                        color: rgb(0, 167, 144);
                        font-weight: 500;
                        font-size: 13px;
                    }
                }
            }
        }
    }

    .divider {
        width: 100%;
        text-align: center;
        color: gray;
        font-size: 14px;
    }

    .section-two {
        width: 100%;
        padding: 20px 0;
        display: grid;
        place-items: center;
        height: 300px;

        >div>div {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        .list-div {
            font-size: 13px;
            font-weight: 600;
            text-align: center;
            display: block;

            p {
                &:first-child {
                    color: gray;
                    margin-bottom: 10px;
                }
            }

        }

        form {
            width: 320px;
            max-width: 100%;
            display: flex;
            align-items: center;
            gap: 10px;
            margin-top: 20px;
            margin-bottom: 25px;

            button {
                @include normalButton();
                background-color: $primary_color;
            }
        }

        .generate-button {

            @include normalButton();
            font-size: 16px;
            padding: 15px 30px;
            background-color: $green_color;

        }
    }

    .result-div {
        padding-top: 30px;

        .buttons-div {
            display: flex;
            gap: 10px;
            justify-content: flex-end;

            button {
                @include normalButton();

                &:first-child {
                    background-color: gray;
                }

                &:last-child {
                    color: rgb(107, 107, 107);
                    background-color: white;
                    border: 1px solid rgb(107, 107, 107);

                    &:hover {
                        background-color: rgb(107, 107, 107);
                        color: white;
                    }
                }
            }
        }

        .top-div {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            align-items: center;
            text-align: center;
            height: 200px;

            .item-div {
                color: $red_color;

                h4 {
                    font-size: 30px;
                }

                p {
                    font-size: 13px;
                    font-weight: 500;
                }


                &:first-child {
                    color: $green_color;
                }

                &:last-child {
                    color: $edit_color
                }
            }

        }

        .info-div {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px;

            .info-border {
                display: grid;
                grid-template-columns: 50px auto;
                padding: 10px 20px 10px 0px;
                border-radius: 8px;
                -webkit-border-radius: 8px;
                -moz-border-radius: 8px;
                -ms-border-radius: 8px;
                -o-border-radius: 8px;
                border: 1px solid gray;
                background-color: rgba(128, 128, 128, 0.200);
                color: gray;

                .left-div {
                    display: grid;
                    place-content: center;
                    font-size: 23px;
                }

                .right-div {
                    h4 {
                        font-size: 14px;
                        font-weight: 700;
                    }

                    p {
                        font-size: 12px;
                        font-weight: 500;
                    }
                }
            }

            .error-border {
                border: 1px solid $red_color;
                background-color: rgb(211, 49, 8, 0.200);
                color: $red_color
            }

            .update-border {
                border: 1px solid $edit_color;
                background-color: rgb(9, 101, 155, 0.200);
                color: $edit_color;


                
            }
        }
    }


    @media screen and (max-width: 700px) {
        .result-div {
            .info-div {
                grid-template-columns: 1fr;
            }

        }
    }
}