@import '../../assets/scss/model.scss';

.purifier-page-div {
    .border {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 12px;
        box-sizing: border-box;

        .button-div {
            button {
                @include normalButton();
                width: 100%;
                padding-top: 20px;
                padding-bottom: 20px;
                font-size: 12px;
            }
        }
    }
}

@media screen and (max-width:750px) {
    .purifier-page-div {
        .border {
            grid-template-columns: 1fr 1fr;
        }
    }
}

@media screen and (max-width:450px) {
    .purifier-page-div {
        .border {
            grid-template-columns: 1fr;
        }
    }
}