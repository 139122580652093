@import '../../../assets/scss/model.scss';
@import '../../../assets/scss/color.scss';

.import-ledger-div {
    .import-border {
        .image-div {
            img {
                width: 510px;
                max-width: 100%;
            }
        }

        .info-div {
            margin-top: 7px;

            ol {
                line-height: 1.9;
                padding: 0 0 0 30px;

                li {
                    font-size: 12px;
                    font-weight: 500;
                }
            }
        }

        .import-button-div {
            margin: 25px 0;
            text-align: center;


            button {
                @include normalButton();
                width: 200px;
                background-color: $edit_color;
            }

            p {
                font-size: 12px;
                font-weight: 500;
                color: $red_color;
            }
        }
    }

    .track-div {
        .top-div {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 10px;
            padding: 40px 0;

            .count-div {
                text-align: center;
                color: $edit_color;

                p {
                    font-size: 13px;
                    font-weight: 500;
                }

                &:first-child {
                    color: $green_color;
                }

                &:last-child {
                    color: $red_color;
                }
            }
        }

        .list-div {
            text-align: center;
            font-size: 13px;
            color: gray;
            margin-bottom: 20px;

            p {
                &:first-child {
                    font-weight: 600;
                    color: black;
                    margin-bottom: 10px;
                }
            }
        }
    }
}