@import '../../assets/scss/color.scss';


.pending-payments-div {
    table {
        .buttons {
            display: flex;
            gap: 10px;
            justify-content: center;
        }

        .bg_red {
            background-color: $red_color;
        }

        .bg_green {
            background-color: $green_color;
        }
    }
}