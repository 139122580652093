@import '../../../assets/scss/color.scss';

.input-model-div {
    padding: 7px 0;

    div {
        width: 100%;
        position: relative;

        select,
        input {
            width: 100%;
            font-size: 13px;
            font-weight: 600;
            padding: 10px 15px;
            outline: none;
            border: none;
            border: 1px rgb(173, 173, 173) solid;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;

            &:focus {
                outline: 1px solid $primary_color;
                border-color: $primary_color;
            }

            &:focus~label {
                color: $primary_color;


            }

            &:focus~label,
            &:valid~label {
                top: -7px;
                left: 10px;
                font-size: 12px;
                font-weight: 500;
                max-width: 85%;
                padding: 0px 5px;
                width: auto;

            }
        }

        label {
            font-size: 13px;
            font-weight: 600;
            position: absolute;
            top: 8px;
            left: 15px;
            color: rgb(85, 85, 85);
            transition: 0.1s ease-in-out;
            -webkit-transition: 0.1s ease-in-out;
            -moz-transition: 0.1s ease-in-out;
            -ms-transition: 0.1s ease-in-out;
            -o-transition: 0.1s ease-in-out;
            background-color: white;
            width: 85%;
            padding: 3px 0;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
        }

        .icon-div {
            position: absolute;
            right: 10px;
            bottom: 9px;
            cursor: pointer;
        }
    }

}