@import '../../../assets/scss/model.scss';
@import '../../../assets/scss/color.scss';

.credit-form-div {

    .amount-div {
        text-align: center;

        p {
            font-size: 13px;
            font-weight: 500;
        }
    }

    .two-input-single-line-div {
        display: flex;
        column-gap: 10px;
        align-items: center;

        .pump-div {
            width: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
                font-size: 13px;
                font-weight: 600;
                text-align: center;
            }
        }

        >div {
            width: 100%;
        }
    }

    .button-div {
        button {
            @include normalButton();
            width: 100%;
            margin-top: 10px;
            background-color: $primary_color;
        }
    }
}