@import '../../../assets/scss/model.scss';

.modal-div {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1;

    .modal-boarder {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        padding: 15px;
        position: relative;

        .modal-shadow-div {
            position: fixed;
            width: 100%;
            height: 100vh;
            top: 0;
            left: 0;
            background-color: rgba(187, 187, 187, 0.274);
            z-index: 1;
            backdrop-filter: blur(3px);
        }

        .modal-content {
            background-color: white;
            width: 350px;
            max-width: 100%;
            height: auto;
            max-height: 80vh;
            border-radius: 15px;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            z-index: 6;
            @include scrollBar();

            .modal-top {
                padding: 10px 15px;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid gray;
                align-items: center;

                .left {
                    h4 {
                        font-size: 13px;
                    }
                }

                .close-icon {
                    cursor: pointer;
                }
            }

            .model-center {
                padding: 10px;
                overflow: auto;
                height: auto;
                max-height: calc(80vh - 50px);
                @include scrollBar();
            }
        }
    }


}