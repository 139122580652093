@import '../../assets/scss/model.scss';
@import '../../assets/scss/color.scss';

.spares-list-div {

    table {
        tbody {
            .name {
                display: block;
            }

            .brand {
                display: block;
                color: gray;
                font-style: italic;
                font-size: 12px;
            }

            .tally_name {
                display: block;
            }
        }
    }


    .add-buttons-div {
        display: flex;
        gap: 10px;

        .add-button {
            @include normalButton();
            background-color: $green_color;
            font-size: 13px;
            display: flex;
            width: max-content;

            svg {
                font-size: 18px;
                margin: 0;
                margin-right: 5px;
            }
        }
    }



    .buttons {
        display: flex;
        gap: 10px;
        justify-content: center;
    }

    .bg_blue {
        background-color: $edit_color;
    }

    .bg_red {
        background-color: $red_color;
    }
}