@import '../../assets/scss/color.scss';
@import '../../assets/scss/model.scss';


.credit-work-div {
    .table-div {

        .buttons {
            display: flex;
            gap: 8px;
            justify-content: center;
        }

        .bg_gray {
            background-color: gray;
        }

        .bg_blue {
            background-color: $edit_color;
        }

        .bg_user {
            background-color: teal;
        }


        table {
            text-align: center;

            tbody {
                .amount-td {
                    font-size: '15px';
                    font-weight: 700;
                    color: $red_color;
                }
            }
        }
    }
}