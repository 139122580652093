@import '../../../assets/scss/model.scss';
@import '../../../assets/scss/color.scss';

.compliment-form-div {

    .amount-div {
        text-align: center;
        p {
            font-size: 13px;
            font-weight: 500;
        }
    }

    .button-div {
        button {
            @include normalButton();
            width: 100%;
            margin-top: 10px;
            background-color: $primary_color;
        }
    }
}