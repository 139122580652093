@import '../../assets/scss/model.scss';

.not-found-div {
    .content-div {
        display: grid;
        place-content: center;
        width: 100%;
        height: calc(100vh - 110px);
        overflow: auto;

        .content {
            text-align: center;

            .image {
                img {

                    width: 300px;
                    max-width: 100%;
                }
            }

            .text {
                margin-top: 50px;
                text-align: center;

                button {
                    @include normalButton();
                    background-color: rgb(0, 111, 155);
                }
            }
        }
    }
}