@import '../../../assets/scss/model.scss';
@import '../../../assets/scss/color.scss';

.add-edit-spares-form {

    form {

        >div {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 10px;
        }

        .checkbox-list {
            position: relative;
            width: 100%;
            margin: 5px 0 5px 0;

            padding: 10px 5px;


            >label {
                top: -7px;
                left: 10px;
                font-size: 12px;
                font-weight: 500;
                max-width: 85%;
                padding: 0px 5px;
                width: auto;
                position: absolute;
                color: rgb(85, 85, 85);
                background-color: white;
            }

            .checkbox-div {
                .items {
                    display: inline;

                    label {
                        display: inline-block;
                        background-color: rgba(223, 223, 223, 0.9);
                        border: 2px solid rgba(139, 139, 139, .3);
                        color: #414141;
                        border-radius: 25px;
                        white-space: nowrap;
                        margin: 3px 3px;
                        user-select: none;
                        -webkit-touch-callout: none;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        -webkit-tap-highlight-color: transparent;
                        padding: 5px 12px;
                        cursor: pointer;
                        font-size: 12px;
                        font-weight: 500;
                        transition: transform .3s ease-in-out;

                    }

                    input[type="checkbox"] {
                        display: none;
                    }

                    input[type="checkbox"]:checked+label {
                        border: 2px solid $primary_color;
                        background-color: rgb(67, 143, 241);
                        color: #fff;
                        transition: all .2s;
                    }

                }
            }

            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
            border: 1px rgb(170, 170, 170) solid;
        }

        .button-div {
            display: block;

            button {
                @include normalButton();
                width: 100%;
                margin-top: 10px;
                background-color: $primary_color;
            }
        }
    }

    @media screen and (max-width:500px) {
        form {
            >div {
                display: grid;
                grid-template-columns: 1fr;
                column-gap: 10px;
            }
        }
    }
}